body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:  'GothamBold', sans-serif
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family:  'GothamBold', sans-serif
}

@font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
   url("./fonts/Gotham/GothamBold.ttf") format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
   url("./fonts/Gotham/GothamMedium.ttf") format("truetype");
  
 }
 @font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
   url("./fonts/Gotham/GothamLight.ttf") format("truetype");
  
 }