.App {
  text-align: center;
  font-family: GothamMedium;
  overflow-x: hidden;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.div1{
  background-color:#772432;
  height:1300px;
}

.div2{
  padding-left:200px;
  padding-right:200px;
   padding-top:50px;
}

.div3{
  padding-left:200px;
  padding-right:200px;
  padding-top:50px;
  background-color:#004165;
  text-align:left;
  padding-bottom:50px;
}

.div4{
  padding-left:200px;
  padding-right:200px;
   padding-top:50px;
   padding-bottom:50px;
    text-align:left;
}
.div5{
  background-color: #772432;
  padding-left:200px;
  padding-right:200px;
   padding-top:50px;
   padding-bottom: 100px;
}

.div6{
  /* background-color: #772432; */
  padding-left:200px;
  padding-right:200px;
   padding-top:50px;
   margin-bottom: 100px;
   margin-top: 50px;
}
 .fmeanu{
  font-size: 20px;
  text-decoration: none;
  color: black;
}
  
 


 .cform{
  border-width: 2px;
  border-color: black;
  border-style: groove;
  border-radius: 10px;
  padding:20px;
  text-align: left;
  margin-top:50px;
 }
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navitem {
  margin-top:5px;
  margin-left: 20px !important;
  margin-right: 20px;
}
.navitem1 {
  /* margin-top:5px; */
  margin-left: 5px !important;
  margin-right: 5px;
}

.middiv {
  background-color:white;
  margin-left:10%;
   border-radius:40px;
    border-width:2px;
     height:1320px;
      width:80%;
      position:relative;
     
      bottom: 10%;
      left: 0px;
      right: 0px;
      /* top:173%; */
       padding:40px 50px 50px 50px;
       
}

.sepdiv1 {
  height: 500px;
  border:4px;
  text-align: left;
  background-color:#D0E6F1;
  padding-left:50px;
  padding-right:50px;
   padding-top:50px;
}

.sepdiv2 {
  height: 500px;
  border:4px;
  text-align: left;
  background-color:#F2DF74;
  padding-left:50px;
  padding-right:50px;
   padding-top:50px;
}
.sepdiv3 {
  height: 500px;
  border:4px;
  text-align: left;
  background-color:#EBEBEB;
  padding-left:80px;
  padding-right:80px;
   padding-top:50px;
   padding-bottom: 150px;
}
.titlediv {
  background-color: #004165;
  margin-right:30%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
 padding-bottom: 1px; 
  margin-bottom: 10px;
  border-radius: 10px;

}
.title {
  color:#D6D6D6;
  font-size: 16px;

}

.title2 {
  color: #424241;
  text-align: left;
  font-size: 32px;
  margin-bottom: 1px !important;
}

.leftpad {
padding-left: 30px;
}
.box {
  width:260px;
  height:260px;
  /* background-color: grey; */
  /* box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
}
.third {
  margin-top: 50px;
}
.third .row{
  margin-bottom: 100px;
}

.boxtitle{
  font-size: 34px;
  color:#424241;
  padding-right:200px;
  text-align: left;
  margin-bottom: 1px;
}

.boxp{
  color:#959595;
  font-size: 18px;
  text-align: left;
  margin-top: 10px;
}

.third .col-md-8{
  padding-right:10px;
}

.videodiv{
  width: 500px;
  height:550px;
  background-color: #2A617F;
  border-radius: 10px;
  margin-bottom: 30px;
}

.line1{
  /* Group 234 */

/* position: absolute;
width: 168.96px;
height: 300.14px;
left: 300.03px;
top: 3507.19px; */



/* Vector 9 */

/* position: absolute; */
width: 165.99px;
height: 300.14px;
left: 468.99px;
/* top: 3507.19px; */

/* True Maroon */
/* border: 1.8px dashed #772432;
transform: matrix(-1, 0, 0, 1, 0, 0); */


/* Polygon 2 */

/* position: absolute;
width: 25px;
height: 25px;
left: 330px;
top: 3734.38px; */

/* True Maroon */
/* background: #772432;
border-radius: 2px;
transform: rotate(167.03deg); */

}

.v0{
  padding-right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
}

.v1 {
  padding-right: 15px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-top: -101%;
    width: 20;
}

.v2 {
  padding-right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-left: 120%;
  margin-top: -152%;
}

.v3{
  padding-right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-top: -100%;
}
.v4{
  padding-right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-top: -147%;
  margin-left: 120%;
}
.v5{
  padding-right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-top: -100%;
}

.n1{
  position: absolute;
  left: 280px;
  width: 50px;
  /* top: 1px; */
  margin-top: -20px;

}


.n2{
  position: absolute;
  left: 280px;
  width: 50px;
  /* top: 1px; */
  margin-top: -20px;
  margin-left: 10%;
}

.im{
  height: 250px;
  background-color: #D9D9D9;
  border-radius: 10px;
}
.videocontent{
  padding:20px 20px 20px 20px;
}
.csize{
  font-size: 18px;
  color:#D9D9D9;
}
.dsize{
  font-size: 28px;
  color:white
}

.nsize{
  font-size: 28px;
  color:white

}

.office{
width: 265px;
height: 335px;
box-shadow: #FE4A5514;
border-radius: 10px;
border-width: 1px;
padding:20px;
margin: 10px;
box-shadow: 0px 1px 1px;
}

.officeim {
  width: 225px;
  height:230px;
  /* background-color:gray; */
  
  border-radius: 10px;
}

.officename {
color:#21225F;
font-size: 20px;
/* padding-top: 10px; */
margin-bottom: 0px !important;
}

.position{
  color:#7D7F8D;
  font-size: 15px;
}

.p1{
  font-size:54px;
   color:#424241;
   padding-left:200px;
   padding-right:200px;
}
.ReactGridGallery_tile-viewport{
  width: 100% !important;
  height: 100% !important;
}

.GridGallery_tile-viewport img{
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 768px) {
  .carousel .slide {
    min-width: 200px;
  }

  .middiv {
    background-color:white;
    margin-left:7%;
     border-radius:40px;
      border-width:2px;
       height:1480px; 
        width:85%;
        position:relative;
       
       bottom:5%;
        /*  left: 0px;
        right: 0px; */
        /* top:293%; */
         padding:40px 20px 40px 20px !important;
         
  }
  .div1{
    background-color:#772432;
    height:1500px;
  }
  .div2{
    padding-left:20px;
    padding-right:20px;
     padding-top:50px;
  }
  
  .div3{
    padding-left:20px;
    padding-right:20px;
    padding-top:50px;
    /* background-color:#004165;
    text-align:left; */
    padding-bottom:50px;
  }
  
  .div4{
    padding-left:20px;
    padding-right:20px;
     padding-top:50px;
     padding-bottom:50px;
      text-align:left;
  }
  .div5{
    padding-left:20px;
    padding-right:20px;
     padding-top:50px;
     padding-bottom:50px;
      text-align:left;
  }
  .div6{
    padding-left:20px;
    padding-right:20px;
     padding-top:10px;
     padding-bottom:0px;
      text-align:left;
  }

  .n1{
    right: 300px;
    left: 15px;
  }
  .n2{
    left: 5px;
  }

.ReactGridGallery_tile-viewport   img{
    cursor: pointer;
    max-width: none;
    height: 100%;
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
  }
  .title {
    color:#D6D6D6;
    font-size: 14px !important;
  
  }
  .title2 {
    /* color: #424241;
    text-align: left; */
    font-size: 18px;
    /* margin-bottom: 1px !important; */
  }
  .titlediv{
    margin-right: 0px !important;
  }

  .p1{
    font-size:24px;
     color:#424241;
     padding-left:0px;
     padding-right:0px;
  }
  
  .boxtitle{
    font-size: 20px;
    color:#424241;
    padding-right:0px;
    text-align: left;
    margin-bottom: 1px;
    /* margin-top: 20px; */
  }

  .box{
    margin-bottom: 20px;
  }
  .dsize{
    font-size: 16px;
    
  }
  .nsize{
    font-size: 18px;
 
  
  }
  .office{
    width: 157px;
    height: 230px;
  
    }
    
    .officeim {
      width: 117px;
      height:130px;
      /* background-color:gray; */
      
      border-radius: 10px;
    }
    .officename {
  
      font-size: 16px;
      margin-bottom: 0px !important;
      }
      
      .position{
    
        font-size: 10px;
      }
      .sepdiv3 {
        height: 140px;
       background-color: #004165;
       color: #ffff;
        padding-left:30px;
        padding-right:30px;
         padding-top:30px;
         /* padding-bottom: 20px; */
      }
      .sepdiv1 {
        height: 200px;
      
        padding-left:30px;
        padding-right:30px;
         padding-top:30px;
         padding-bottom: 30px;
         /* padding-bottom: 0px; */
      }
      .sepdiv2{
        height: 300px;
      
        padding-left:30px;
        padding-right:30px;
         padding-top:30px;
      }


      .videodiv{
        width: 100%;
        height:470px;
        /* background-color: #2A617F;
        border-radius: 10px;
        margin-bottom: 30px; */
      }

      .carousel.carousel-slider .control-arrow {
        /* top: 0;
        color: #fff;
        font-size: 26px; */
        bottom: 10% !important;
        /* margin-top: 0;
        padding: 5px; */
    }
      .carousel .control-prev.control-arrow:before {
        border-right: 20px solid #000 !important;
    }
    .carousel .control-next.control-arrow:before {
      border-left: 20px solid #000 !important;
  }
    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
      margin: 0 5px;
      display: inline-block;
      border-top: 20px solid transparent !important;
      border-bottom: 20px solid transparent !important;
      content: '';
  }
  .mhero{
    margin-top: 50px;
    padding-right: 0px !important;
  }

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}